import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";

function Share() {
  return (
    <section>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-5">
            <p className="fs-4">
              <b>Share</b> your want list. We'll get it <b>done</b> from there.
            </p>
            <p className="mt-3">
              <i>
                Really, we mean it. Jot down the complete details you want to
                include in your project. Tech-savvy CMARIANs are here to share
                the expertise you required yesterday and deliver outstanding
                results. So hire dedicated developer to start with.
              </i>
            </p>
          </div>
          <div className="col-lg-7 d-flex justify-content-center align-items-center pt-4">
            <div className="share-round d-flex justify-content-center align-items-center">
              <div className="share-pointer"></div>
              <div className="row-lg-3">
                <h4 className="text-center pb-3">Click below to</h4>
                <button className="share-button text-white">
                  Share Your Idea
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Share;
