import React from "react";
import SEO from "../common/SEO";
import "../assets/css/style.css";


const GraphicDesign = () => {
  return (
    <>
      <SEO title="Graphic Designing" />
      <>
  <section className="homedesign-why newintro">
    <div className="overlay">
      <div className="container-fluid h-100">
        <div className="row align-items-center color-bor h-100">
          <div className="col-lg-5 col-md-10 ml-lg-auto mx-md-auto align-self-center">
            <img
              src="/images/floatin-man.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-lg-5 col-md-10 mx-md-auto">
            <p>
              Playing the role of your design partner, 3Way creates beautiful
              designs that metamorphose end customer constraints into
              opportunities of growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="stats-design">
    <div className="stats-overlay section">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-10 mx-auto">
            <h2>Our data speaks for us</h2>
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-5 col-6 mx-auto">
                <div className="stat-wrap">
                  <p className="statistic-number stat-d">
                    <span
                      className="c-section4 timer count-title count-number"
                      data-to={100}
                    >
                      100
                    </span>
                    +
                  </p>
                  <p>Web Design</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-6 mx-auto">
                <div className="stat-wrap">
                  <p className="statistic-number stat-d">
                    <span
                      className="c-section4 timer count-title count-number"
                      data-to={500}
                    >
                      500
                    </span>
                    +
                  </p>
                  <p>Graphics</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-6 mx-auto">
                <div className="stat-wrap">
                  <p className="statistic-number stat-d">
                    <span
                      className="c-section4 timer count-title count-number"
                      data-to={30}
                    >
                      30
                    </span>
                    +
                  </p>
                  <p>Branding</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-6 mx-auto">
                <div className="stat-wrap">
                  <p className="statistic-number stat-d">
                    <span
                      className="c-section4 timer count-title count-number"
                      data-to={500}
                    >
                      500
                    </span>
                    +
                  </p>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section >
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="owl-carousel owl-theme">
            <div className="item">
              <img src="/images/1.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/2.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/3.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/4.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/5.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/6.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/7.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/8.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/9.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/10.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/11.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/12.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/13.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/14.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/15.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/16.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/17.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/18.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/19.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/20.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/21.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/22.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/23.png" alt="image" />
            </div>
            <div className="item">
              <img src="/images/24.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="why-sec"
    style={{
      position: "static",
      top: 80,
      left: 0,
      width: "100%",
      zIndex: 999,
      backgroundColor: "#29a5de"
    }}
  >
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 p-o">
          <div className="responsive-tabs">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-items">
                <a
                  id="tab-A"
                  href="#why"
                  className="nav-link active"
                  data-toggle="tab"
                  role="tab"
                >
                  Why
                </a>
              </li>
              <li className="nav-items">
                <a
                  id="tab-B"
                  href="#how"
                  className="nav-link "
                  data-toggle="tab"
                  role="tab"
                >
                  How
                </a>
              </li>
              <li className="nav-items">
                <a
                  id="tab-C"
                  href="#what"
                  className="nav-link "
                  data-toggle="tab"
                  role="tab"
                >
                  What
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="why-content section" id="why">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="row">
            <div className="col-lg-4 mx-auto">
              <div className="Why-wrap">
                <h2 style={{ color: "white" }}>Why us?</h2>
                <p>
                  Whether you're looking to launch a new product or simply want
                  to refresh your existing branding, we can help you achieve
                  your goals.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="Whybox-wrap">
                <div className="boxhead-wrap">
                  <h3>We understand you</h3>
                  <img
                    src="https://vajraglobal.com/wp-content/uploads/2022/06/1-circle.svg"
                    alt="creative-design-circle"
                  />
                </div>
                <p>
                  We can help you develop a unique visual identity for your
                  business that will help you attract attention and customers.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="Whybox-wrap">
                <div className="boxhead-wrap">
                  <h3>We speak your language</h3>
                  <img
                    src="https://vajraglobal.com/wp-content/uploads/2022/06/2-circle.svg"
                    alt="creative-design-circle"
                  />
                </div>
                <p>
                  Versatile with a vision, the team conveys your message to your
                  audience in your tone, style and branding.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="Whybox-wrap">
                <div className="boxhead-wrap">
                  <h3>
                    We hold the highest
                    <br />
                    standards
                  </h3>
                  <img
                    src="https://vajraglobal.com/wp-content/uploads/2022/06/3-circle.svg"
                    alt="creative-design-circle"
                  />
                </div>
                <p>
                  Holding the bar high with technical excellence and creativity,
                  our design team leaves no stone unturned.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="Whybox-wrap">
                <div className="boxhead-wrap">
                  <h3>We deliver results</h3>
                  <img
                    src="https://vajraglobal.com/wp-content/uploads/2022/06/4-cir.svg"
                    alt="creative-design-circle"
                  />
                </div>
                <p>
                  Our designers are equipped with result-oriented strategies and
                  customer-centric culture to bring your brand to the spotlight.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="Whybox-wrap">
                <div className="boxhead-wrap">
                  <h3>We go the extra mile</h3>
                  <img
                    src="https://vajraglobal.com/wp-content/uploads/2022/06/5-circle.svg"
                    alt="creative-design-circle"
                  />
                </div>
                <p>
                  Technologically focused customized solutions fused with
                  digital marketing strategies ensure timely delivery of SMART
                  goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section designrule" id="how">
    <div className="container-fluid">
      <div className="row text-center">
        <div className="col-lg-11 mx-auto text-center">
          <div className="col-lg-10 mx-auto  text-center">
            <h2>How do we deliver?</h2>
            <p>
              Design is a personal element. What works for one company will not
              work for another. That is our underlying philosophy. We become the{" "}
              <strong>graphic design partner</strong> that you can trust and are
              confident about delivering personalized solutions that meet your
              standards.
            </p>
          </div>
          <div className="designrule-img">
            <div className="box">
              <img src="/images/ask.jpg" alt="images" className="img-fluid" />
              <p>Ask</p>
            </div>
            <div className="box">
              <img
                src="/images/re-Search.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Research</p>
            </div>
            <div className="box">
              <img
                src="/images/ideat.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Ideate</p>
            </div>
            <div className="box">
              <img
                src="/images/design.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Design</p>
            </div>
            <div className="box">
              <img
                src="/images/develop.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Develop</p>
            </div>
            <div className="box">
              <img
                src="/images/deploy.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Deploy</p>
            </div>
            <div className="box">
              <img
                src="/images/support.jpg"
                alt="images"
                className="img-fluid"
              />
              <p>Support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="design-services section" id="what">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <h2>Our services</h2>
          <img
            src="/images/service.jpg"
            alt="images"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  </section>
  <section className="branding-delicate">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h2>BRANDING </h2>
          <div className="row">
            <div className="col-lg-2 col-md-12 mx-auto branding-delicate-bg">
              <div className="branding-delicate-firstsection">
                <p>
                  Branding is a delicate art that engineers a relationship with
                  the customer. Design is "the most obvious manifestation of
                  branding".{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-9 branding-delicate-secondsection me-auto">
              <img src="/images/Mask-Group-36-copy.webp" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="branding-delicate-bottomsection">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 col-md-12 mx-auto">
          <div className="row">
            <div className="col-lg-6 col-md-12 brand-service1">
              <p>
                A brand is not simply a logo or a name. It is the sum of all of
                the interactions that a customer has with a company. From the
                initial contact to post-purchase follow-up, every interaction
                provides an opportunity to build (or damage) a relationship.
                That’s why it’s so important to have a clear and consistent
                brand identity that is reflected in every aspect of your
                business.
              </p>
              <p>
                At 3Way Technologies, we understand the importance of branding
                and we work with our clients to develop a strong and cohesive
                brand identity. We begin by understanding your target audience
                and your business goals. We then create a{" "}
                <strong>unique brand position and messaging strategy</strong>{" "}
                that will resonate with your target customers. Finally, we apply
                these insights across all touchpoints, from website design to
                social media campaigns to email marketing.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 brand-service2 mx-auto">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <p className="yellowfont">Our brand services focus on: </p>
                </div>
              </div>
              <ul>
                <li>BRAND DNA</li>
              </ul>
              <ul>
                <li>VOICE/TONALITY</li>
              </ul>
              <ul>
                <li>POSITIONING</li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 brand-service3 me-auto">
              <ul className="ps-0">
                <li>PRESENTATIONS AND DEMOS</li>
              </ul>
              <ul className="ps-0">
                <li>BRAND LOGO</li>
              </ul>
              <ul className="ps-0">
                <li>BRAND GUIDELINES</li>
              </ul>
              <ul className="ps-0">
                <li>COLLATERALS AND SALES MATERIALS</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="nucleus-uidesign">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="row">
            <div className="col-lg-7 col-md-12 nucleus-uidesign-leftdesc">
              <div className="nucleus-uidesign-left">
                <h2>UI DESIGNS </h2>
                <span>
                  <p>
                    A website’s user interface (UI) is one of the most important
                    aspects of its design. It determines how easy it is for
                    users to navigate the site and find the information they
                    need. A well-designed UI can make the difference between a
                    website that’s successful and one that is not. That’s why as
                    a <strong>web design company</strong>, we offer UI services
                    to our clients.
                  </p>
                  <p>
                    Our team of experts will work with you to design a UI that’s
                    both user-friendly and visually appealing. We take into
                    account your target audience, your brand identity, and your
                    overall objectives for the site. We make sure that the UI
                    integrates seamlessly with the rest of the website’s design
                    and is compatible with any device. With our help, you can
                    create an attractive and fast-loading website that not only
                    looks great but is also easy for users to navigate and ranks
                    high on the search engine.
                  </p>
                </span>
                <p className="yellowfont">The nucleus of our UI design: </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <ul className="nucleus-uidesign-list1">
                    <li>UX DESIGN</li>
                  </ul>
                  <ul className="nucleus-uidesign-list1">
                    <li>WIREFRAMING</li>
                  </ul>
                  <ul className="nucleus-uidesign-list1">
                    <li>VISUAL DESIGN</li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6">
                  <ul className="nucleus-uidesign-list2">
                    <li>RESPONSIVE DESIGN </li>
                  </ul>
                  <ul className="nucleus-uidesign-list2">
                    <li>INTERACTION DESIGN </li>
                  </ul>
                  <ul className="nucleus-uidesign-list2">
                    <li>PROTOTYPING </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="nucleus-uidesign-right">
                <img src="/images/uiux.jpg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="marketing-collaterlal section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="row marketing-collaterlal-first pb-0">
            <div className="col-lg-7 p-0">
              <div className="marketing-collaterlal-firstleft">
                <p style={{ display: "none" }}>MARKETING COLLATERAL</p>
                <img src="/images/marketing-collateral.webp" alt="image" />
              </div>
            </div>
            <div className="col-lg-5 align-self-center">
              <div className="marketing-collaterlal-firstright">
                <h2>MARKETING COLLATERAL</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row marketing-collaterlalbottomsection">
        <div className="col-lg-11 col-md-12 mx-auto">
          <div className="row">
            <div className="col-lg-6 col-md-12 marketing-collatersection1">
              <p>
                <strong>Marketing collaterals</strong> like flyers, brochures
                and decks are an essential part of any branding strategy. They
                help to create a strong visual identity for a brand and provide
                potential customers with information about products and
                services. Good collateral can help to capture attention, convey
                key messages, and build trust with potential customers. It can
                also be a valuable tool for helping your sales team to close
                deals.
                <br />
                <br />
                For these reasons, we offer a range of collateral services that
                are designed to help our clients achieve their marketing goals.
                From creating eye-catching flyers and brochures to developing
                engaging website content, we have the skills and experience to
                produce collateral that gets results.
              </p>
            </div>
            <div className="col-lg-2 mx-auto col-md-6 marketing-collatersection3">
              <p className="yellowfont ps-0">Physical:</p>
              <ul className="ps-0">
                <li>BROCHURES</li>
                <li>FLYERS</li>
                <li>BUSINESS &amp; IDENTITY CARDS</li>
                <li>LETTERHEADS</li>
                <li>ENVELOPE</li>
                <li>BACKDROPS / PODIUM</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 marketing-collatersection2 mx-auto pe-0">
              <p className="yellowfont">Digital:</p>
              <ul>
                <li>BROCHURES</li>
                <li>PRESENTATIONS</li>
                <li>INFOGRAPHICS</li>
                <li>ILLUSTRATION</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="social-media-design">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 col-md-11 mx-auto">
          <div className="row">
            <div className="col-lg-6 col-md-12 section">
              <div className="social-media-left">
                <h2>SOCIAL MEDIA DESIGN</h2>
                <p className="social-media-left-para">
                  Social media is one of the most powerful tools that businesses
                  have at their disposal. It allows you to connect with your
                  target audience, build relationships, and grow your brand.
                  However, social media can also be a time-consuming and
                  challenging platform to navigate. That's where our social
                  media design services come in.
                  <br />
                  <br />
                  We can help you create stunning visuals that will grab
                  attention and get results. We'll also develop a{" "}
                  <strong>strategic plan for your social media presence</strong>
                  , so you can make the most of this powerful marketing tool.
                </p>
                <p className="yellowfont">The designing process involves:</p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <ul className="social-media-designlist1">
                      <li>IDEATION</li>
                      <li>DEVELOPMENT</li>
                      <li>CREATION OF VISUAL CONTENT</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-12 social-media-designlist2">
                    <ul>
                      <li>CAROUSELS</li>
                      <li>GIF / VIDEO</li>
                      <li>OPTIMIZING FOR DIFFERENT CHANNELS</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="social-media-right">
                <img src="/images/social media.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="newsandemail section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-11 col-md-12 mx-auto">
          <div className="row newsandemail-first">
            <div className="col-lg-6 col-md-12 mx-auto">
              <div className="newsandemail-firstleft">
                <p style={{ display: "none" }}>NEWSLETTER &amp; EMAILER</p>
                <img src="/images/news latter.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 align-self-center">
              <div className="newsandemail-firstright">
                <h2>NEWSLETTER &amp; EMAILER</h2>
              </div>
            </div>
          </div>
          <div className="row newsandemail-bottomsection">
            <div className="col-lg-11 col-md-12 mx-auto">
              <div className="row">
                <div className="col-lg-8 col-md-12 newsandemailsection1">
                  <span>
                    <p>
                      At our <strong>digital marketing agency</strong>, we
                      understand the importance of staying in touch with your
                      customers and keeping them updated on your latest news and
                      offerings. That’s why we offer a variety of newsletter and
                      emailer design services to fit your needs. Whether you’re
                      looking for a simple, text-based design or a more
                      elaborate, graphical approach, we can create a custom
                      solution that will get your message across clearly and
                      effectively.
                    </p>
                    <p>
                      And because we know that everyone’s time is precious,
                      we’ll make sure that your newsletter or email is delivered
                      on time and without any hitches.
                    </p>
                  </span>
                </div>
                <div className="col-lg-4 col-md-12 newsandemail">
                  <div className="row">
                    <p className="yellowfont">The creative process involves:</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 newsandemailsection2">
                      <ul>
                        <li>DEFINE A CLEAR GOAL</li>
                      </ul>
                      <ul>
                        <li>CRAFTING THE DESIGN</li>
                      </ul>
                      <ul>
                        <li>DEVELOPMENT</li>
                      </ul>
                      <ul>
                        <li>PUBLISH &amp; ANALYSE THE RESULT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="brand-videos section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-5 brand-videosleftdesc ml-auto">
              <div className="brand-videosleft">
                <h2>VIDEOS </h2>
                <span>
                  <p />
                  <p>
                    Brands are brought to life through videos. Videos stand out
                    because they demonstrate rather than narrate. We build the
                    brand pulse via our video content. Our videos can improve
                    business conversion rates and help users love the brand.
                  </p>
                  <p>
                    Our digital marketing agency offers video creating services
                    that are engaging and professional. We can help you create
                    videos that promote your brand, product, or service. Our
                    team of experts can also assist you in storyboarding,
                    scripting, and creating graphics for your video. We believe
                    that <strong>video is a powerful tool for marketing</strong>
                    , and we are committed to helping our clients create videos
                    that are impactful and successful.
                  </p>
                </span>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 brand-videos-title1">
                  <p className="yellowfont">Why we need a video : </p>
                </div>
              </div>
              <ul className="brand-videos-middlecontent">
                <li>Sales &amp; Marketing </li>
                <li>Customer Success </li>
                <li>Employee Communication </li>
              </ul>
              <div className="row">
                <div className="col-lg-12 col-md-12 brand-videos-title2">
                  <p className="yellowfont">Our outlook for video creation:</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <ul className="brand-videoslist1">
                    <li>REQUIREMENT GATHERING </li>
                    <li>SCRIPT WRITING </li>
                    <li>STORY BOARDING</li>
                    <li> ASSET CREATION</li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="brand-videoslist2">
                    <li>VOICE OVER RECORDING</li>
                    <li>PRODUCTION</li>
                    <li>OPTIMIZATION AND DISTRIBUTION</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-end brand-videosright pr-0 ml-auto">
              <img src="/images/videos.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
 
  <section id="stay-in-touch">
    <div className="stay-overlay">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-lg-8">
                <h5>Stay in touch with us</h5>
                <p>We will only send you the best marketing information</p>
              </div>
              <div className="col-lg-4 align-self-center">
                <a href="/contact" className="contact">
                  CONTACT US
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </>
  );
};

export default GraphicDesign;
