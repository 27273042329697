import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Cutting-edge technology",
        para: "We are committed to using the latest and most advanced technology to deliver innovative solutions to our clients. Working with us will give you the opportunity to work with state-of-the-art tools and technologies, allowing you to stay at the forefront of the IT industry."
    },
    {
        id: 2,
        title: "Talented and diverse team",
        para: "Our team is made up of highly skilled professionals from a variety of backgrounds, cultures, and experiences. This diversity creates a collaborative and dynamic environment where you can learn from your colleagues and grow your skills."
    },
    {
        id: 3,
        title: "Challenging projects",
        para: "We work with a variety of clients across multiple domains, which means you'll have the opportunity to work on challenging and interesting projects that will push you to your limits and help you grow as a professional."
    },
    {
        id: 4,
        title: "Career growth opportunities",
        para: "We are committed to investing in our employees' career growth and development. You'll have access to training and development opportunities, mentorship programs, and career advancement paths to help you achieve your professional goals."
    },
    {
        id: 5,
        title: "Positive impact on society",
        para: "Our IT solutions have a positive impact on society, and we are committed to creating solutions that make a difference in people's lives. When you work with us, you'll have the opportunity to contribute to meaningful projects that make the world a better place."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description="If you're looking for an innovative and dynamic IT company to work with, look no further. Our team is comprised of highly skilled professionals with a passion for cutting-edge technology and a commitment to delivering top-notch solutions to our clients. We pride ourselves on our ability to understand our clients' needs and to provide customized solutions that meet their specific requirements."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;