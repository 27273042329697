function WebCards() {
  const cards_list = [
    {
      text: "Competitor analysis & Strategy planning",
      content: `While defining our web design strategy, we analyze the
      competitor data to identify opportunities and threats.
      Stakeholders sit together to decide on what to do and what
      not to do in the UI/UX strategy. We lay out the dots to
      join.`,
    },
    {
      text: "User persona & empathy mapping",
      content: `Since every website design is different, we create user
      personas to define the audience a business targets. This
      helps in joining the dots to qualitative and quantitative
      research. This leads us to understand what the user is
      thinking or feeling.`,
    },
    {
      text: "Information Architecture",
      content: `We have IA as our secret weapon to present disasters like
      costly redesigns! Information Architecture helps in
      organizing, labeling and structuring content in a way that
      makes the user complete intended tasks in the website
      journey.`,
    },
    {
      text: "Creating low fidelity sketches",
      content: `As we gather the IA, we get a fair understanding of
      composing the screen structure. We start creating on paper
      prototypes, using navigations and content action for user
      engagement journeys. Early feedback and less reworks makes
      everyone's life easier.`,
    },
    {
      text: "Creating high fidelity wireframes",
      content: `As our designers complete the sketches, they transfer the
      paper prototypes into high fidelity wireframes by defining
      exact shape, size and reference of how the actual screen
      would look like after development.`,
    },
    {
      text: "Setting up UI guidelines",
      content: `The last step is preparing a foolproof guidesheet where we
      list down the UI guidelines with color palette, icons,
      typography and their components. For every design we do, we
      have a client requirement specific UI guideline.`,
    },
  ];
  return cards_list;
}

export default WebCards;
