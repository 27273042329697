import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";

function Message() {
  return (
    <section>
      <div className="container pt-3">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6">
            <h3 className="text-start mess-text">
              Messages That Mean Something
            </h3>
            <p className="pt-3 text-start">
              Being impulsive is not an option when it comes to building a good
              narrative for a website design. We engage in developing meaningful
              content whether it be an enterprise website or an educational one,
              thus establishing a deeper connection with the audience. We build
              user-centric models that separate us from other NYC web design
              services in the industry.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Message;
