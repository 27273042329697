import React from 'react';
import { FaInstagram, FaBehance, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">Technology is only as good as the people behind it</h1>
                    <p>Our world-class agile teams partner with you to build impactful products fast, on time and on budget.</p>
                    <div>
                    <a  href="/projects" className="axil-btn btn-fill-white btn-large">Our Work</a>
                        {/* <Link to="https://portfolio.3waytech.co" className="axil-btn btn-fill-primary btn-large">View Showcase</Link> */}
                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                        <Tilty perspective={3000}>
                            <img src={process.env.PUBLIC_URL + "/images/banner/banner-2.png"} alt="Shape" />
                        </Tilty>
                    </div>
                </div>
                <div className="banner-social">
                <div className="border-line" />
                    <ul className="list-unstyled social-icon">
                    <li><a href="https://www.linkedin.com/company/3-way-technologies" target='_blank'><FaLinkedinIn /> Linkedin</a></li>
                        <li><a href="https://instagram.com/3waytech" target='_blank'><FaInstagram /> Instagram</a></li>
                        <li><a href="https://www.behance.net/3waytechnologies" target='_blank'><FaBehance /> Behance</a></li>
                        
                    </ul>
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
            
        </div>
    )
}

export default BannerFour;