import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance, FaPhone, FaFax } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><a href="https://portfolio.3waytech.co/Web-Development" target="_blank">Website Development</a></li>
                            <li><a href="https://portfolio.3waytech.co/App-Development" target="_blank">Mobile App Development</a></li>
                            <li><a href="https://portfolio.3waytech.co/graphics" target="_blank">Graphic Designing</a></li>
                            <li><a href="https://portfolio.3waytech.co/UI-UX" target="_blank">UI/UX</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>503, Abhishree Adroit, Vastrapur<br /> Ahmedabad, India</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:+919998692099" className="tel"><FaPhone /> (+91) 999-869-2099</a>
                                    <a href="tel:+918155881434" className="tel"><FaPhone /> (+91) 815-588-1434</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/3waytech" target="_blank"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://twitter.com/3waytech1" target="_blank"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/gallery/159120637/3-Way-Brand-Identity" target="_blank"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/3-way-technologies" target="_blank"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
