import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import Team_list from "./team_list";

function Team() {
  const team = Team_list();
  return (
    <section>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-5">
                <div className="row ps-3">
                  <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                    <div className="col-lg-3 col-3 project-back-line"></div>
                  </div>
                  <span className="col-lg-8 col-10 fw-bolder fst-italic">
                    Why Hire Developers?
                  </span>
                </div>
              </div>
              <h3 className="ps-3 pt-4">
                Why hire dedicated development team?
              </h3>
            </div>
            <div className="row pt-5">
              {team.map((data) => {
                return (
                  <div className="team-benefite col-lg-6 col-md-6">
                    <div>
                      <div className="team-img-box">
                        <img className="team-img-set" src={data.img}></img>
                      </div>
                    </div>
                    <h4>{data.title}</h4>
                    <p className="pt-4">{data.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
