import React from "react";
import food_icon from "../../../assets/dedicated_icons/food-icon.svg";
import icon_commerce from "../../../assets/dedicated_icons/icon-commerce.svg";
import icon_pro_auditing from "../../../assets/dedicated_icons/icon-pro-auditing.svg";
import icon_outsource from "../../../assets/dedicated_icons/icon_outsource.svg";
import icon_media from "../../../assets/dedicated_icons/icon_media.svg";
import icon_finance from "../../../assets/dedicated_icons/icon_finance.svg";
import icon_insurance from "../../../assets/dedicated_icons/icon_insurance.svg";
import icon_startup_consulting from "../../../assets/dedicated_icons/icon_startup_consulting.svg";
import icon_education from "../../../assets/dedicated_icons/icon_education.svg";
import sports_icon from "../../../assets/dedicated_icons/sports_icon.svg";
import logistics from "../../../assets/dedicated_icons/logistics.svg";
import agritech_icon from "../../../assets/dedicated_icons/agritech_icon.svg";

function ExpertiseList() {
  const list = [
    {
      img: icon_commerce,
      text: "Ecommerce",
    },
    {
      img: icon_pro_auditing,
      text: "Healthcare",
    },
    {
      img: icon_outsource,
      text: "Real Estate",
    },
    {
      img: icon_media,
      text: "Media",
    },
    {
      img: icon_finance,
      text: "Finance",
    },
    {
      img: icon_insurance,
      text: "Insurance",
    },
    {
      img: icon_startup_consulting,
      text: "Security",
    },
    {
      img: icon_education,
      text: "Education",
    },
    {
      img: sports_icon,
      text: "Sports",
    },
    {
      img: logistics,
      text: "Logistic",
    },
    {
      img: agritech_icon,
      text: "Agritech",
    },
    {
      img: food_icon,
      text: "Food",
    },
  ];
  return list;
}

export default ExpertiseList;
