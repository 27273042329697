import React from "react";

function ServiceList() {
  const list = [
    {
      text: "Research & Strategy",
      content: `Analysing and understanding the aspects that impacts success
    will ensure that we jump off the competitive edge. We work on
    different factors from markets to competition to understand
    user minds.`,
    },
    {
      text: "UI/UX Design",
      content: `We work on a concrete design strategy. Different elements from
      feel and view to experiences are brought together with
      different tools to bring attractive user experiences to you.`,
    },
    {
      text: "Enterprise Development",
      content: `We nurture industry-leading talent to deliver development
      services that bring out confidence and clarity. We work with
      you at every step of digital strategy from development to
      marketing.`,
    },
    {
      text: "Testing",
      content: `With the use of modern technologies, CMARIANs ensure your app
      works flawlessly in aspects of usability and functionality.
      They collaboratively work with our dedicated developers to
      bring out the best performance for your app.`,
    },
  ];
  return list;
}

export default ServiceList;
