import React from "react";

export const Data = [
  {
    image: "/images/brand/1.png",
  },
  {
    image: "/images/brand/2.png",
  },
  {
    image: "/images/brand/3.png",
  },
  {
    image: "/images/brand/4.png",
  },
  {
    image: "/images/brand/5.png",
  },
  {
    image: "/images/brand/6.png",
  },
  {
    image: "/images/brand/7.png",
  },
  {
    image: "/images/brand/8.png",
  },
  {
    image: "/images/brand/9.png",
  },
  {
    image: "/images/brand/10.png",
  },
  {
    image: "/images/brand/11.png",
  },
  {
    image: "/images/brand/12.png",
  },
  {
    image: "/images/brand/13.png",
  },
  {
    image: "/images/brand/14.png",
  },
  {
    image: "/images/brand/15.png",
  },
  {
    image: "/images/brand/16.png",
  },
  {
    image: "/images/brand/17.png",
  },
  {
    image: "/images/brand/18.png",
  },
  {
    image: "/images/brand/19.png",
  },
  {
    image: "/images/brand/20.png",
  },
  {
    image: "/images/brand/21.png",
  },
  {
    image: "/images/brand/22.png",
  },
  {
    image: "/images/brand/23.png",
  },
  {
    image: "/images/brand/24.png",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div className="brand-grid">
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
