import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import agile_img from "../../../assets/dedicated_icons/agile-team.png";

function Agile() {
  return (
    <section className="pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="row">
              <div className="col-lg-10">
                <div className="row ps-3">
                  <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                    <div className="col-lg-3 col-3 project-back-line"></div>
                  </div>
                  <span className="col-lg-6 col-10 fw-semibold fst-italic">
                    Know Us
                  </span>
                </div>
              </div>
              <h3 className="ps-3 pt-4">
                We Are The Rare Agile Team With<br></br> Triple Power Threat
              </h3>
              <p className="pt-3">
                We are a close-knit team of innovators and problem solvers. We
                ensure world-class products are delivered with the
                implementation of strategy, technology and design. With our
                triple power -{" "}
                <b>extreme capability, flexibility to work with</b>, and{" "}
                <b>dedication to our work</b>, we have made some great stories
                for Fortune 500 brands and few startups. Do you want us to
                inundate the world with your cool idea?
              </p>
              <div className="d-flex justify-content-start pt-3">
                <div className="d-flex align-items-center justify-content-center">
                  <button className="button-bg text-white fw-bolder">
                    Meet Our Team{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <img src={agile_img} className="agile-img"></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Agile;
