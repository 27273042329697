import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../../utils';
import { FaArrowRight} from 'react-icons/fa';

const PropOne = ({projectStyle, portfolio}) => {
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
				{portfolio.directLink ? <a target="_blank" href={portfolio.directLink} ><img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" /></a> :<Link to={ process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</Link>}
				
				</div>
				<div className="content">
				<h4 className="title"> 
				{portfolio.directLink ? <a target="_blank" href={portfolio.directLink} >{portfolio.title} <FaArrowRight /></a>:
					<Link to={portfolio.directLink ? `https://portfolio.3waytech.co` : process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>{portfolio.title}</Link>}
				</h4>
				<span className="subtitle">
					{portfolio.category.map((cat, i) => (
						<span key={i}>{cat}</span>
					))}
				</span>
				</div>
			</div>
		</>
    )
}

export default PropOne;