import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import { useState, useEffect } from "react";
import developer_img from "../../../assets/dedicated_icons/developer_img_1.svg";

function Developers() {
  const [screenChange, setScreenChange] = useState(false);
  const [webView, setWebView] = useState(
    window.innerWidth > 550 ? true : false
  );

  useEffect(() => {
    const setData = () => {
      setScreenChange(!screenChange);
      if (window.innerWidth < 550) {
        setWebView(false);
      } else {
        setWebView(true);
      }
    };

    window.addEventListener("resize", setData);
    return () => {
      window.removeEventListener("resize", setData);
    };
  }, [webView, screenChange]);

  return (
    <section className="developer-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 developer-padding pb-5">
            <div className="back-line">
              <div className="line"></div>
            </div>
            <h3> Hire Dedicated Developers</h3>
            <p>
              Are you struggling to find skilled developers for your project?
              Look no further! Hire dedicated developers that provide a
              cost-effective and efficient solution to your development needs.
            </p>
            <p>
              Are you struggling to find skilled developers for your project?
              Look no further! Hire dedicated developers that provide a
              cost-effective and efficient solution to your development needs.
            </p>
            <button className="btn btn-light btn-hover-dark text-black">
              Contact Us{" "}
            </button>
          </div>

          {webView && (
            <div className="col-lg-6 col-md-6">
              <div className="row justify-content-center align-items-end">
                <div className="container container-position">
                  <div className="col-lg-4">
                    <div className="circle-sm"></div>
                  </div>
                  <div className="col-lg-4">
                    <div className="circle"></div>
                  </div>
                  <div className="col-lg-4 developer-img">
                    <img src={developer_img}></img>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Developers;
