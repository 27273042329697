import React from "react";
import App from "../developers/index";
import Project from "../project/index";
import Dedicated from "../dedicated/index";
import Share from "../share/index";
import Services from "../services/index";
import Core from "../core_services/index";
import Process from "../process/index";
import Team from "../team/index";
import Domain from "../domain/index";
import Agile from "../agile/index";
import Do from "../we_do/index";

function Hire() {
  return (
    <div>
      <App />
      <Project />
      <Dedicated />
      <Share />
      <Services />
      <Core />
      <Process />
      <Team />
      <Domain />
      <Agile />
      <Do />
    </div>
  );
}

export default Hire;
