import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState, useEffect } from "react";
import { Data } from "../../brand/BrandItem";

function Branding() {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
  });

  return (
    <section className="slider-bg">
      <div className="container slider-container-height">
        <div className="row">
          <OwlCarousel
            className="owl-theme-st"
            loop
            margin={100}
            items={
              screenSize.dynamicWidth < 500
                ? 2
                : screenSize.dynamicWidth < 550 || screenSize.dynamicWidth < 800
                ? 3
                : screenSize.dynamicWidth < 1030
                ? 5
                : 7
            }
            autoplayTimeout={1000}
            autoWidth={false}
          >
            {Data.map((data) => {
              return (
                <div>
                  <img src={data.image}></img>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default Branding;
