import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';
import { FaReact, FaNodeJs, FaAngular, FaPhp, FaPython, FaJava, FaWordpress, FaAndroid, FaApple } from "react-icons/fa";



const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
            <SectionTitle 
                    subtitle="Customized solutions for every need"
                    title="Our Tech Stack"
                    description="We focus on emerging technologies for building high performing web & mobile products that can be securely deployed in the cloud."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row pc-center">
                
                <div id="wrapper">
      <div id="carousel">
        <div id="content">
                <div class="col-lg-3 col-6"><div class="brand-grid"><FaReact color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"><FaNodeJs color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaAngular color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaPhp color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid">  <FaPython color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaJava color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaWordpress color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid2"> <img className='mw-200' src={process.env.PUBLIC_URL + "/images/project/flutter-50.png"} size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaAndroid color='white' size={"60px"} /></div></div>
                <div class="col-lg-3 col-6"><div class="brand-grid"> <FaApple color='white' size={"60px"} /></div></div>

                </div>
                </div>
                </div>

                    {/* <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" /> */}
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;