import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";

function Creativity() {
  return (
    <section className="creativity-design">
      <div className="container-lg">
        <div className="creativity-text-margin row align-items-center">
          <div className="creativity-text-data col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 text-black pt-5">
            <h2 className="creativity-title ms-sm-2">
              Creativity & rational thinking into web design & development
            </h2>
            <p className="creativity-description ms-sm-2">
              In a time when websites are competing in the race for visibility
              and engagement, we help our clients to stand out by going beyond
              the straightforward conventional ways. Keeping users as the prime
              focus, we feel them from their perspective to make sure our
              efforts 'engage' them. From keeping a creative touch to color,
              messaging layout, iconography and the UX, we layout the UI design
              that brings on board the max number of users.
              <strong> Our web design</strong> specializes in engaging
              in-house talents who can actually turn your idea into reality.
            </p>
          </div>
          <div className="creativity-value-box col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="row mx-0">
              <div className="creativity-value-box-item creativity-align creativity-value-box-item-border col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="creativity-value-box-item-img">
                  <img src="https://www.unifiedinfotech.net/assets/images/new-home/Clutch-Accountability-Icon.svg"></img>
                </div>
                <div>
                  <p className="creativity-title-1">Accountability</p>
                </div>
              </div>
              <div className="creativity-value-box-item-border creativity-align col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="creativity-value-box-item-img">
                  <img src="https://www.unifiedinfotech.net/assets/images/new-home/Clutch-Quality-Icon.svg"></img>
                </div>
                <div>
                  <p className="creativity-title-1">Quality</p>
                </div>
              </div>
              <div className="creativity-value-box-item creativity-align col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="creativity-value-box-item-img">
                  <img src="https://www.unifiedinfotech.net/assets/images/new-home/Clutch-Proactivity-Icon.svg"></img>
                </div>
                <div>
                  <p className="creativity-title-1">Proactivity</p>
                </div>
              </div>
              <div className="creativity-align col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="creativity-value-box-item-img">
                  <img src="https://www.unifiedinfotech.net/assets/images/new-home/Clutch-Agility-Icon.svg"></img>
                </div>
                <div>
                  <p className="creativity-title-1">Agility</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="creativity-value row align-items-center">
          <div className="creativity-value-item col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <div className="text-black text-center">
              <p className="creativity-value-title">
                <h3 className="creativity-title-2">7+ years</h3>
                in the business
              </p>
            </div>
          </div>
          <div className="creativity-value-item col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <div className="text-black text-center">
              <p className="creativity-value-title">
                <h3>98%</h3>
                client retention
              </p>
            </div>
          </div>
          <div className="creativity-value-item col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <div className="text-black text-center">
              <p className="creativity-value-title">
                <h3>450+</h3>
                solutions delivered
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <div className="text-black text-center">
              <p className="creativity-value-title">
                <h3>10x</h3>
                revenue growth in 5 years
              </p>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="text-center">
            <button className="btn btn-dark">
              Share Your Project Requirement
            </button>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Creativity;
