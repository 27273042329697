import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import { DesignCards, LowerDesignCards } from "./design_card";

function DesignPrinciple() {
  const card_list = DesignCards();
  const lower_card_list = LowerDesignCards();
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="row-lg-6">
            <div className="row">
              <div className="col-lg-6 pt-5">
                <ul className="principle-border principle-wrap">
                  <li className="verticaltext">ATTRACT CUSTOMERS</li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li className="verticaltext">ENGAGE CUSTOMER</li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li className="verticaltext">ACQUIRE CUSTOMER</li>
                </ul>
                <h2 className="principle-text">
                  Our Design Principles That Fetch Optimum Conversions
                </h2>
                <p className="principle-content">
                  Reports say that web design is the prime determinant of a
                  company's credibility. We understand the importance of making
                  it as professional as possible.
                </p>
              </div>
              <div className="col-lg-6 pt-5">
                <div className="row ps-2">
                  {card_list.map((data) => {
                    return (
                      <div class="col-lg-6 col-md-6 pe-2">
                        <div className="card principle-card">
                          <div class="card-body">
                            <h4 class="p-card-title text-white">
                              {data.title}
                            </h4>
                            <p class="p-card-text text-white">{data.content}</p>
                            <a className="text-white">{data.a_tag}</a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row-lg-6">
            <div className="row">
              {lower_card_list.map((data) => {
                return (
                  <div class="col-lg-3 col-md-6 pe-2">
                    <div className="card principle-card">
                      <div class="card-body">
                        <h4 class="p-card-title text-white">{data.title}</h4>
                        <p class="p-card-text text-white">{data.content}</p>
                        <a className="text-white">{data.a_tag}</a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DesignPrinciple;
