import React from "react";
import SEO from "../common/SEO";
import "../assets/css/style.css";


const FlutterAppDevelopment = () => {
  return (
    <>
      <SEO title="Flutter App Development" />
      <div>
  
  {/* <section className="flutter-banner section">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12">
          <div className="fltr-banner-con">
            <ul className="banner-list-style">
              <li>
                <a href="#">Home /</a>
              </li>
              <li>
                <a href="#">Services /</a>
              </li>
              <li>
                <a href="#">Flutter app development</a>
              </li>
            </ul>
            <h1>
              Flutter App
              <br /> Development Company
            </h1>
            <p>
              Build Robust &amp; Natively Compiled Experiences
              <br />
              for Web, Mobile, and Desktop
            </p>
            <a href="#" className="consultant">
              <span className="text">
                Consult our Flutter Experts
                <span className="arrow">
                  <i className="fa-solid fa-arrow-right" />
                </span>
              </span>
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="banner-img">
            <img src="/images/Rectangle.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/* <section className="small-slider">
    <div className="container">
      <div className="trusted_wrapper">
        <div className="trusted-line">TRUSTED BY</div>
        <div
          className="owl-carousel owl-theme owl-loaded owl-drag"
          id="trusted-by"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(-1080px, 0px, 0px)",
                transition: "all 0.25s ease 0s",
                width: 2160
              }}
            >
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/13.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/14.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/15.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 170, marginRight: 10 }}>
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/16.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 170, marginRight: 10 }}>
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/17.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 170, marginRight: 10 }}>
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/18.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/19.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/20.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 170, marginRight: 10 }}>
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/21.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/22.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/23.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 170, marginRight: 10 }}
              >
                <div className="item">
                  <div className="trusted-logo">
                    <figure>
                      <img src="/images/24.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        </div>
      </div>
      <div className="service-wrap">
        <div className="d-flex">
          <div className="service-con">
            <h2>Flutter App Development Services</h2>
            <p>
              Your digital product idea should never get restricted to mobile
              phones. Our Flutter app developers will help you build
              platform-independent digital experiences.
            </p>
            <a href="#">
              Discuss your goals with us{" "}
              <span>
                <i className="fa-solid fa-arrow-right" />
              </span>
            </a>
          </div>
          <div className="service-scroll">
            <div className="scroll-panel">
              <div
                className="scrolldiv"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "auto",
                  height: 500
                }}
              >
                <ul
                  id="d-services"
                  style={{ overflowY: "scroll", width: "auto", height: 500 }}
                >
                  <li>
                    <h4>
                      Flutter App Ideation &amp; Consulting
                      <span>
                        <i className="fa-solid fa-lightbulb" />
                      </span>
                    </h4>
                    <p className="paragraph">
                      Flutter app development has come a long way since its
                      inception both in terms of framework maturity and use
                      cases. We are a flutter app development agency that can
                      help you validate your Flutter app idea and help you
                      ensure that the framework is the best technical solution
                      for your product idea.
                    </p>
                  </li>
                  <li>
                    <h4>
                      Flutter App Development
                      <span>
                        <i className="fa-solid fa-lightbulb" />
                      </span>
                    </h4>
                    <p className="paragraph">
                      Our Flutter mobile app development services are devised to
                      help you create a multi-platform existence. Right from
                      finding the best product-market fit to seamlessly
                      deploying your application across a number of platforms,
                      our flutter developer can help you design, develop, test,
                      and launch full-fledged Flutter apps.
                    </p>
                  </li>
                  <li>
                    <h4>
                      Flutter App Migration
                      <span>
                        <i className="fa-solid fa-lightbulb" />
                      </span>
                    </h4>
                    <p className="paragraph">
                      We help you migrate your Android and iOS app development
                      using Flutter. Our team of Flutter Android app development
                      and deployment experts specializes in taking your existing
                      application across platforms and operating systems. We are
                      the Flutter app development company that the world trusts
                      for making platform-agnostic applications.
                    </p>
                  </li>
                </ul>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  <section className="grey-panel">
    <div className="container">
      <h2>
        Partner With Us To Create Highly Scalable <br /> Apps Using Flutter{" "}
      </h2>
      <p>
        We understand the importance of having a visually unique brand image
        that is an extension of your business. Every iOS, Android, and web app
        that we create with our Flutter developer is a spitting image of the
        brand that it represents. We are a flutter app development company that
        ensures that all the Material Design &amp; Cupertino components we use
        in your apps are highly customizable to give your users a Native replica
        application on every platform they use.
      </p>
      <p>
        What results from this combination of feature-rich Flutter SDK and the
        aesthetically rich design component is an app that is powered by an
        expressive, flexible UI and robust architecture.
      </p>
      <p>
        That is why, 3 Way Technologies is considered the most reliable and impeccable
        company for flutter app development in USA, Asia, and European Regions.
        Our flutter developer team has expertise in building world class apps,
        we have developed a number of applications that have created new
        milestones.
      </p>
      {/* <div className="img-grid-wrap">
        <div className="imggrid first">
          <figure>
            <img
              src="/images/annie-spratt-QckxruozjRg-unsplash.jpg"
              alt="image"
            />
          </figure>
        </div>
        <div className="imggrid second">
          <figure>
            <img
              src="/images/cherrydeck-rMILC1PIwM0-unsplash.jpg"
              alt="image"
            />
          </figure>
        </div>
        <div className="imggrid third">
          <figure>
            <img
              src="/images/kevin-bhagat-zNRITe8NPqY-unsplash.jpg"
              alt="image"
            />
          </figure>
        </div>
        <div className="imggrid fourth">
          <figure>
            <img
              src="/images/marvin-meyer-SYTO3xs06fU-unsplash.jpg"
              alt="image"
            />
          </figure>
        </div>
      </div> */}
    </div>
  </section>
  <section className="flutter-benifits">
    <div className="container">
      <h2>
        Flutter App Development Benefits <br />
        Go Beyond Cost-Effectiveness
      </h2>
      <p className="text-center">
        Our Flutter iOS app development services are built on the best of the
        framework’s offerings which extend beyond <br /> cost benefits. We use
        the SDK’s native end-user experience, expressiveness, flexible UI, and
        lightning-fast load speed of 60 <br /> FPS to ensure that your
        application becomes the symbol of cross-platform excellence.
      </p>
      <div className="grid_benefits">
        <div className="num-text">
          <span className="para">
            <span>01</span>Fast Development
          </span>
        </div>
        <div className="num-text bene2">
          <span className="para">
            {" "}
            <span>02</span>Expressive and Flexible UI
          </span>
        </div>
        <div className="num-text bene3">
          <span className="para">
            {" "}
            <span>03</span>Native Performance
          </span>
        </div>
        <div className="num-text">
          <span className="para">
            <span>04</span>Easy Integration
          </span>
        </div>
        <div className="num-text bene5">
          <span className="para">
            {" "}
            <span>05</span>Scalability Friendly
          </span>
        </div>
        <div className="num-text bene6">
          <span className="para">
            <span>06</span>Single Codebase
          </span>
        </div>
      </div>
      <a href="/contact" className="pop-text text-center">
        Create a competitive Flutter App
        <span>
          <i className="fa-solid fa-arrow-right" />
        </span>
      </a>
      <div className="dot-bg" />
      <div className="circle">
        <img src="/images/anim-circle-btm.png" alt="image" />
      </div>
    </div>
  </section>
  <section className="work-flow">
    <div className="container">
      <h2>
        Flutter App Development Projects Built to <br />
        Accelerate Business Revenue
      </h2>
      <p>
        Our Flutter mobile app development process carries innovation &amp; user
        experience at the center of it. Irrespective of which platform your app
        is being used on, our process guarantees zero platform differences. We
        are also a leading flutter web development company that has helped
        businesses to design high-quality native interfaces on cross-platform.
      </p>
      {/* <div className="work-flow-idea">
        <div className="owl-carousel owl-theme owl-loaded" id="wf-idea">
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0.25s ease 0s",
                width: 2064,
                paddingLeft: 15,
                paddingRight: 15
              }}
            >
              <div
                className="owl-item active"
                style={{ width: 329, marginRight: 10 }}
              >
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img
                        src="/images/planning-icon.svg"
                        alt="Design vector"
                      />
                    </figure>
                    <span>1</span>
                    <h4>Planning</h4>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 329, marginRight: 10 }}
              >
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img
                        src="/images/design-icon-blue.svg"
                        alt="Design vector"
                      />
                    </figure>
                    <span>2</span>
                    <h4>Design</h4>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 329, marginRight: 10 }}
              >
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img
                        src="/images/single-codebase-development.svg"
                        alt="flutter app development Single Codebase"
                      />
                    </figure>
                    <span>3</span>
                    <h4>
                      Single Codebase <br className="br-block" />
                      Development
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 329, marginRight: 10 }}
              >
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img
                        src="/images/Quality-Assurance-icon.svg"
                        alt="Quality Assurance"
                      />
                    </figure>
                    <span>4</span>
                    <h4>Quality Assurance</h4>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 329, marginRight: 10 }}>
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img src="/images/deploying-icon.svg" alt="Deployment" />
                    </figure>
                    <span>5</span>
                    <h4>Deployment</h4>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 329, marginRight: 10 }}>
                <div className="item">
                  <div className="workflow-grid">
                    <figure>
                      <img
                        src="/images/sup-main-icon.svg"
                        alt="Support & Maintenance"
                      />
                    </figure>
                    <span>6</span>
                    <h4>Support &amp; Maintenance</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div> */}
    </div>
  </section>
  {/* <section className="working-app">
    <div className="container">
      <h2>
        Our Business Associates <br />
        on Working with 3 Way Technologies{" "}
      </h2>
      <div className="slide-panel">
        <div
          className="owl-carousel owl-theme owl-loaded owl-drag"
          id="testimonial"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(-6510px, 0px, 0px)",
                transition: "all 0s ease 0s",
                width: 10230
              }}
            >
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        3 Way Technologies's work has been professional and impactful.
                        They remained prompt throughout the project and
                        continued to provide marketing services till after the
                        app launch. They took the time to understand our startup
                        idea and then deliver quality results to propel them in
                        a new direction.
                      </p>
                      <div className="client_name_wrap">
                        <strong>David A.</strong>
                        <span>Image sharing iphone App</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client3.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        We were looking for an agency that would understand the
                        direness of the hourly worker job search cycle situation
                        when we came across 3 Way Technologies. What we liked about the
                        team is how they did not just understand what we were
                        looking for but also gave us ideas on how we could make
                        the process more efficient and simplified for the
                        jobseekers through their empathy mapping skillset.{" "}
                      </p>
                      <div className="client_name_wrap">
                        <strong>Billy Lan</strong>
                        <span>Jobget</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client-image.png"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        3 Way Technologies is extremely professional, detailed, and a
                        pleasure to work with. Despite numerous delays from my
                        team's side, they have always been easy and flexible in
                        handling issues. I very much appreciate the detailed
                        planning, weekly updates, and adherence to process. I
                        highly recommend them for any project development, and
                        will be happy to work with them again in the future.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Stephen Ceresia</strong>
                        <span>iOS and Android App for Real estate</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client4.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        Highly recommend 3 Way Technologies to any new startup
                        companies looking to get a mobile application or website
                        developed. Working from conceptualization, final designs
                        and finally to development was a fluid and easy process.
                        Thank you to the 10+ designers and developers who each
                        had a hand in making this application a success.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Keith</strong>
                        <span>Project TimeKeeper</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client2.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        We envisioned a platform that would make flight booking
                        intuitive and a quick in and out experience. 3 Way Technologies
                        team helped us achieve just that through their AI
                        algorithms capabilities.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Michael Kimura</strong>
                        <span>iOS Gift Giving App</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client6.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        3 Way Technologies's work has been professional and impactful.
                        They remained prompt throughout the project and
                        continued to provide marketing services till after the
                        app launch. They took the time to understand our startup
                        idea and then deliver quality results to propel them in
                        a new direction.
                      </p>
                      <div className="client_name_wrap">
                        <strong>David A.</strong>
                        <span>Image sharing iphone App</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client3.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        We were looking for an agency that would understand the
                        direness of the hourly worker job search cycle situation
                        when we came across 3 Way Technologies. What we liked about the
                        team is how they did not just understand what we were
                        looking for but also gave us ideas on how we could make
                        the process more efficient and simplified for the
                        jobseekers through their empathy mapping skillset.{" "}
                      </p>
                      <div className="client_name_wrap">
                        <strong>Billy Lan</strong>
                        <span>Jobget</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client-image.png"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        3 Way Technologies is extremely professional, detailed, and a
                        pleasure to work with. Despite numerous delays from my
                        team's side, they have always been easy and flexible in
                        handling issues. I very much appreciate the detailed
                        planning, weekly updates, and adherence to process. I
                        highly recommend them for any project development, and
                        will be happy to work with them again in the future.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Stephen Ceresia</strong>
                        <span>iOS and Android App for Real estate</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client4.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        Highly recommend 3 Way Technologies to any new startup
                        companies looking to get a mobile application or website
                        developed. Working from conceptualization, final designs
                        and finally to development was a fluid and easy process.
                        Thank you to the 10+ designers and developers who each
                        had a hand in making this application a success.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Keith</strong>
                        <span>Project TimeKeeper</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client2.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        We envisioned a platform that would make flight booking
                        intuitive and a quick in and out experience. 3 Way Technologies
                        team helped us achieve just that through their AI
                        algorithms capabilities.
                      </p>
                      <div className="client_name_wrap">
                        <strong>Michael Kimura</strong>
                        <span>iOS Gift Giving App</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client6.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 930 }}>
                <div className="item">
                  <div className="client-panel">
                    <div className="client-panel-quote">
                      <p className="para">
                        3 Way Technologies's work has been professional and impactful.
                        They remained prompt throughout the project and
                        continued to provide marketing services till after the
                        app launch. They took the time to understand our startup
                        idea and then deliver quality results to propel them in
                        a new direction.
                      </p>
                      <div className="client_name_wrap">
                        <strong>David A.</strong>
                        <span>Image sharing iphone App</span>
                      </div>
                    </div>
                    <div className="client-panel-img">
                      <figure>
                        <img
                          src="https://3 Way Technologies.com/wp-content/themes/twentynineteen-child/images/client3.jpg"
                          alt="3 Way Technologies clients"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  <section class="mobile-app-sec-3 mobile-app-new-york-sec-3 black-bg-info">
          <div class="container">
            <div class="row justify-content-left">
              <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
                <div class="message-text-wrap">
                  <div class="text-message-info commontext text-white">
                    <h2 class="h3" style={{ color: "#d1d1d1" }}>
                      How 3 Way Technologies, #1 app developer in India, makes
                      your app idea a reality?
                    </h2>
                    <p class="mb-5">
                      We believe in creating value for our customers. And
                      pre-planning is the best way to start. We conduct
                      stakeholder interviews and address their needs. We
                      identify their target audience, prospects, budget, and
                      schedules. Our team of app developers and designers in the
                      breathtaking Madison Avenue, Manhattan, plan the optimum
                      requirements to carry out the individual processes. Not
                      only that, we test several functionalities to see which
                      one suits best. This helps our clients to receive the best
                      without having to compromise on their vision. Credit goes
                      to the agile app development process we follow.
                    </p>
                    <h5 class="text-uppercase">
                      Don’t believe us? Check out our{" "}
                      <a
                        class="text-white text-underline"
                        href="https://3waytech.co/projects/"
                      >
                        works!
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wirefram-img-wrap">
            <div class="row">
              <div class="col-sm-12">
                <div class="design-box mb-xl-3 mb-lg-3 mb-md-3">
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                    /images/high-fidelity-wireframe-nyc@4x.png,
                                    /images/high-fidelity-wireframe-nyc@2x.png,
                                    /images/high-fidelity-wireframe-nyc@1.5x.png,
                                    /images/high-fidelity-wireframe-nyc@1x.png,
                                    /images/high-fidelity-wireframe-nyc@0.75x.png
                                    "
                    src="/images/high-fidelity-wireframe-nyc@2x.png"
                    alt="app developers India"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="wirefram-mobileimg-wrap">
            <div class="swiper-container swiper-container-initialized swiper-container-horizontal">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="2"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Payment-for-Subscription@4x.png,
                                /images/Payment-for-Subscription@2x.png,
                                /images/Payment-for-Subscription@1.5x.png,
                                /images/Payment-for-Subscription@1x.png,
                                /images/Payment-for-Subscription@0.75x.png
                                "
                    src="/images/Payment-for-Subscription@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="3"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation@4x.png,
                                /images/Personalisation@2x.png,
                                /images/Personalisation@1.5x.png,
                                /images/Personalisation@1x.png,
                                /images/Personalisation@0.75x.png
                                "
                    src="/images/Personalisation@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="4"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation_01@4x.png,
                                /images/Personalisation_01@2x.png,
                                /images/Personalisation_01@1.5x.png,
                                /images/Personalisation_01@1x.png,
                                /images/Personalisation_01@0.75x.png
                                "
                    src="/images/Personalisation_01@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                  data-swiper-slide-index="5"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Write_a_review@4x.png,
                                /images/Write_a_review@2x.png,
                                /images/Write_a_review@1.5x.png,
                                /images/Write_a_review@1x.png,
                                /images/Write_a_review@0.75x.png
                                "
                    src="/images/Write_a_review@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-active"
                  data-swiper-slide-index="0"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation_05@4x.png,
                                /images/Personalisation_05@2x.png,
                                /images/Personalisation_05@1.5x.png,
                                /images/Personalisation_05@1x.png,
                                /images/Personalisation_05@0.75x.png
                                "
                    src="/images/Personalisation_05@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-next"
                  data-swiper-slide-index="1"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Main-Home@4x.png,
                                /images/Main-Home@2x.png,
                                /images/Main-Home@1.5x.png,
                                /images/Main-Home@1x.png,
                                /images/Main-Home@0.75x.png
                                "
                    src="/images/Main-Home@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="2"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Payment-for-Subscription@4x.png,
                                /images/Payment-for-Subscription@2x.png,
                                /images/Payment-for-Subscription@1.5x.png,
                                /images/Payment-for-Subscription@1x.png,
                                /images/Payment-for-Subscription@0.75x.png
                                "
                    src="/images/Payment-for-Subscription@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="3"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation@4x.png,
                                /images/Personalisation@2x.png,
                                /images/Personalisation@1.5x.png,
                                /images/Personalisation@1x.png,
                                /images/Personalisation@0.75x.png
                                "
                    src="/images/Personalisation@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="4"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation_01@4x.png,
                                /images/Personalisation_01@2x.png,
                                /images/Personalisation_01@1.5x.png,
                                /images/Personalisation_01@1x.png,
                                /images/Personalisation_01@0.75x.png
                                "
                    src="/images/Personalisation_01@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate-prev"
                  data-swiper-slide-index="5"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Write_a_review@4x.png,
                                /images/Write_a_review@2x.png,
                                /images/Write_a_review@1.5x.png,
                                /images/Write_a_review@1x.png,
                                /images/Write_a_review@0.75x.png
                                "
                    src="/images/Write_a_review@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                  data-swiper-slide-index="0"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation_05@4x.png,
                                /images/Personalisation_05@2x.png,
                                /images/Personalisation_05@1.5x.png,
                                /images/Personalisation_05@1x.png,
                                /images/Personalisation_05@0.75x.png
                                "
                    src="/images/Personalisation_05@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                  data-swiper-slide-index="1"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Main-Home@4x.png,
                                /images/Main-Home@2x.png,
                                /images/Main-Home@1.5x.png,
                                /images/Main-Home@1x.png,
                                /images/Main-Home@0.75x.png
                                "
                    src="/images/Main-Home@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="2"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Payment-for-Subscription@4x.png,
                                /images/Payment-for-Subscription@2x.png,
                                /images/Payment-for-Subscription@1.5x.png,
                                /images/Payment-for-Subscription@1x.png,
                                /images/Payment-for-Subscription@0.75x.png
                                "
                    src="/images/Payment-for-Subscription@2x.png"
                    alt="app developers India"
                  />
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="3"
                  style={{ width: "163.75px", marginRight: "60px" }}
                >
                  <img
                    class="blur-out"
                    data-sizes="xl, l, m, s, xs"
                    data-srcset="
                                /images/Personalisation@4x.png,
                                /images/Personalisation@2x.png,
                                /images/Personalisation@1.5x.png,
                                /images/Personalisation@1x.png,
                                /images/Personalisation@0.75x.png
                                "
                    src="/images/Personalisation@2x.png"
                    alt="app developers India"
                  />
                </div>
              </div>
              <span
                class="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </div>
            <div class="swiper-scrollbar">
              <div
                class="swiper-scrollbar-drag"
                style={{
                  transform: "translate3d(167.299px, 0px, 0px)",
                  transitionDuration: "0ms",
                  width: "0px",
                }}
              ></div>
            </div>
          </div>
        </section>
 <br></br>
  <section className="google-dart">
    <div className="container">
      <h2>
        Google Dart Team Backs Our
        <br />
        Flutter Application Development Journey
      </h2>
      <p>
        3 Way Technologies is a part of the prestigious Google Developer Agency
        Program.
      </p>
      <p>
        Our team has been trained by Google Agency Program for Dart. It enables
        us to develop apps
        <br /> on Flutter using Dart which is highly expressive, gives Native
        performance, and loads at the
        <br /> speed of 60 FPS.
      </p>
      <div className="agency-img">
        <figure>
          <img src="/images/logo.png" alt="image" />
        </figure>
      </div>
      <div className="grid-pics">
        <div className="box-c">
          <figure>
            <img src="/images/surface-coCGW4SsxX4-unsplash.jpg" alt="" />
          </figure>
        </div>
        <div className="box-b">
          <figure>
            <img src="/images/jud-mackrill-Of_m3hMsoAA-unsplash.jpg" alt="" />
          </figure>
        </div>
      </div>
    </div>
  </section>
 

      </div>
    </>
  );
};

export default FlutterAppDevelopment;
