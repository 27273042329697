import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import CapabilityCards from "./cards";
import { useState, useRef } from "react";
import { Link } from 'react-router-dom';

function Capabilities() {
  const cards_list = CapabilityCards();
  return (
    <section>
      <div className="section-padding development-section container-fluid">
        <div className="row pt-5">
          <div className="row">
            <div className="d-flex justify-content-center pb-5">
              <h3 className="text-center w-sm-75 capability-text">
                Our capabilities
              </h3>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="row web-development-container ">
              {cards_list.map((data) => {
                return (
                  <div class="col-lg-4 col-md-6 pe-2">
                    <div className="services-grid service-style-2">
                      <div class="card-body">
                      <Link to={process.env.PUBLIC_URL + `/service/website-development`}>
                        <h3 class="card-title text-black">{data.text}</h3>
                        <p class="card-text text-black pb-2">{data.content}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center">
            <button className="axil-btn btn-large btn-fill-white"><Link to={process.env.PUBLIC_URL + `/contact`}>Contact Us</Link></button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Capabilities;

// function Capabilities() {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);

//   const handleCapture = () => {
//     const context = canvasRef.current.getContext("2d");
//     context.arc(150, 150, 100, 0, Math.PI * 2);
//     context.clip();
//     context.drawImage(videoRef.current, 0, 0, 300, 300);
//     const dataURL = canvasRef.current.toDataURL("image/png");
//     console.log(dataURL); // You can save this dataURL as an image
//   };

//   return (
//     <div>
//       <video ref={videoRef} />
//       <button onClick={handleCapture}>Capture</button>
//       <canvas ref={canvasRef} width={300} height={300} />
//     </div>
//   );
// }

// export default Capabilities;
