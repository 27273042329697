import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import Img_icons from "./img";

function Services() {
  const { icon_dict, icon_overlay, icon_green_overlay } = Img_icons();
  return (
    <section className="service-model">
      <div className="service-bg"></div>
      <div className="container pt-5">
        <div className="row">
          <div className="row">
            <div className="col-lg-5">
              <div className="row ps-3">
                <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                  <div className="col-lg-3 col-3 project-back-line"></div>
                </div>
                <span className="col-lg-9 col-10">
                  Hire Dedicated Developers
                </span>
              </div>
              <h3 className="pt-4 ps-2">Our Services</h3>
            </div>
          </div>
          <div className="row pt-3">
            {icon_dict.map((data, index) => {
              return (
                <div className="col-lg-2 col-md-3 col-6 d-flex justify-content-center pt-3">
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="services-round d-flex justify-content-center">
                        <img
                          className="service-icon-bg"
                          src={
                            index % 2 === 0 ? icon_green_overlay : icon_overlay
                          }
                        ></img>
                        <img src={data.image} className="services-icon-1"></img>
                      </div>
                    </div>
                    <h6 className="service-text-position pt-3 text-center">
                      {data.text}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default Services;
