import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ProjectPropOne from "../component/project/itemProp/ProjectPropOne";
import WebsiteDesign from "../component/website_design/new_york_comp";
import Hire from "../component/dedicated/hire-comp/index";
import { slugify } from "../utils";
import ServiceData from "../data/service/ServiceMain.json";
import ProjectData from "../data/project/ProjectData.json";
import ProcessOne from "../component/process/ProcessOne";
import MobileAppDevelopment from "../pages/MobileAppDevelopment";
import FlutterAppDevelopment from "../pages/FlutterAppDevelopment";
import AndroidAppDevelopment from "../pages/AndroidAppDevelopment";
import GraphicDesign from "../pages/GraphicDesign";
import EcomDevelopment from "../pages/EcomDevelopment";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
// Css Import
import "../assets/css/style.css";

const allServiceData = ServiceData;
const getProjectData = ProjectData;

const ServiceDetails = () => {
  const params = useParams();
  const serviceSlug = params.slug;
  console.log(params.slug);
  const getServiceData = allServiceData.filter(
    (data) => slugify(data.title) === serviceSlug
  );
  const detailsService = getServiceData[0];

  return (
    <>
      {/* <SEO title="Services" /> */}
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title={detailsService.title}
          paragraph={detailsService.description}
          styleClass=""
          mainThumb={
            detailsService.thumb
              ? detailsService.thumb
              : "/images/banner/banner-thumb-4.png"
          }
        />
        {params.slug == "mobile-app-development" ? (
          <MobileAppDevelopment />
        ) : null}
        {params.slug == "mobile-app-design" || params.slug == "development" ? <MobileAppDevelopment /> : null}

        {params.slug == "dedicated-developers" ? <Hire /> : null}

        {params.slug == "website-design" || params.slug == "website-development" || params.slug == "design" ? <WebsiteDesign /> : null}

        {params.slug == "flutter-app-development"? <FlutterAppDevelopment /> : null}
        {params.slug == "android-app-development"? <AndroidAppDevelopment /> : null}
        {params.slug == "ecommerce-development"? <EcomDevelopment /> : null}
        {params.slug == "logo-branding" || params.slug == "graphic-design" ? <GraphicDesign /> : null}
        {/* GraphicDesign         */}

        {/* <AboutTwo /> */}

        <ProcessOne />
        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Our Project"
              title="Featured Projects"
              description=""
              textAlignment=""
              textColor=""
            />
            <div className="row row-35">
              {getProjectData.slice(0, 2).map((data) => (
                <div className="col-md-6" key={data.id}>
                  <ProjectPropOne projectStyle="" portfolio={data} />
                </div>
              ))}
              <a
                target="_blank"
                href="https://portfolio.3waytech.co"
                className="axil-btn btn-fill-white btn-large"
              >
                View All Projects
              </a>
            </div>
          </div>
          <ul className="shape-group-16 list-unstyled">
            {/* <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li> */}
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <CtaLayoutOne />

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceDetails;
