import React from "react";
import Stories from "../stories/index";
import Branding from "../slider/index";
import Tech from "../tech_companies/index";
import Creativity from "../creativity/index";
import Capabilities from "../capabilities/index";
import Talk from "../talk/index";
import Platform from "../vedio_platform/index";
import Design from "../web_design/index";
import Message from "../messages/index";
import Mobile_design from "../mobile_design/index";
import DesignPrinciple from "../design_ principles/index";
import SEO from "../../../common/SEO";

function WebsiteDesign() {
  return (
    <>
     <SEO title="Website Development" />
    <div className="mt-5">
      {/* <Stories /> */}
      <Creativity />
      <Branding />
      {/* <Tech /> */}
     
      <Capabilities />
      <Talk />
      <Platform />
      <Design />
      <Message />
      <Mobile_design />
      <DesignPrinciple />
    </div>
    </>
   
  );
}

export default WebsiteDesign;
