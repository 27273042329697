import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import ServiceList from "./service";

function Core() {
  const Service_list = ServiceList();
  return (
    <section>
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="row ps-3 pb-3">
              <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                <div className="col-lg-3 col-3 project-back-line"></div>
              </div>
              <span className="col-lg-6 col-10">Win & Wow With</span>
            </div>
            <h3 className="pt-4 ps-2">Our Core Services</h3>
          </div>
        </div>
        <div className="row pt-5 d-flex justify-content-center align-items-center">
          {Service_list.map((data, index) => {
            return (
              <div className="row pt-5">
                <div className="col-lg-4">
                  <div className="row">
                    <h5 className="text-start">
                      0{index + 1}.&emsp;{data.text}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-8">
                  <p>
                    <i className="fw-semibold">{data.content}</i>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Core;
