import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";

function Process() {
  return (
    <section className="process-model">
      <div className="process-bg"></div>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="row ps-3">
              <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                <div className="col-lg-3 col-3 project-back-line"></div>
              </div>
              <span className="col-lg-6 col-10">Our Process</span>
            </div>
            <h3 className="pt-4 ps-2">Steps to Engage</h3>
          </div>
        </div>
      </div>
      <div className="row pt-5 d-flex process-scroll">
        <div className="process-item-container ">
          <div className="process-item">
            <div className="process-text d-flex align-items-center">
              <h3>1</h3>
            </div>
          </div>
          <h6 className="pt-4 text-white">Post your project requirement</h6>
        </div>
        <div className="process-line">
          <div className="process-arrow"></div>
        </div>
        <div className="process-item-container ">
          <div className="process-item">
            <div className="process-text d-flex align-items-center">
              <h3>2</h3>
            </div>
          </div>
          <h6 className="pt-4 text-white">
            Discuss project details with our technical analysts
          </h6>
        </div>
        <div className="process-line">
          <div className="process-arrow"></div>
        </div>
        <div className="process-item-container ">
          <div className="process-item">
            <div className="process-text d-flex align-items-center">
              <h3>3</h3>
            </div>
          </div>
          <h6 className="pt-4 text-white">
            Select engagement model and timeline
          </h6>
        </div>
        <div className="process-line">
          <div className="process-arrow"></div>
        </div>
        <div className="process-item-container ">
          <div className="process-item">
            <div className="process-text d-flex align-items-center">
              <h3>4</h3>
            </div>
          </div>
          <h6 className="pt-4 text-white">And we start coding</h6>
        </div>
      </div>
    </section>
  );
}

export default Process;
