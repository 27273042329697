import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";

function Talk() {
  return (
    <section>
      <div className="container">
        <div className="row text-center talk-padding">
          <div>
            <h1>Our work does the talking..</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Talk;
