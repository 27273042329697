import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useState, useEffect } from "react";
import FormTwo from "../../../component/contact/FormTwo";

function Stories() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const [mobileView, setMobileView] = useState(
    window.innerWidth <= 550 ? true : false
  );
  const [screenChange, setScreenChange] = useState(false);
  const [webView, setWebView] = useState(
    window.innerWidth > 550 ? true : false
  );

  useEffect(() => {
    const setData = () => {
      setScreenChange(!screenChange);
      if (window.innerWidth < 550) {
        setMobileView(true);
        setWebView(false);
      } else {
        setWebView(true);
        setMobileView(false);
      }
    };

    window.addEventListener("resize", setData);

    return () => {
      window.removeEventListener("resize", setData);
    };
  }, [webView, mobileView, screenChange]);
  return (
    <section>
      <div className="section-padding intro-newyork">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-0">
          <div className="container stories-top d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-5 col-md-5 d-flex align-items-center">
                <div className="stories-padding-div">
                  <h1 className="text-white fs-4">
                    A top-rated web design company in India
                  </h1>
                  <div className="m-2 p-2">
                    <button type="button" class="btn btn-light">
                      SUCCESS STORIES
                    </button>
                  </div>
                </div>
              </div>
              {webView && (
                <div className="col-lg-7 col-md-7 stories-form-padding">
                  <div className="stories-contact-form-box shadow-box mb--30">
                    <h3 className="stories-title">Get in touch now</h3>
                    <FormTwo />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {mobileView && (
        <div className="col-lg-4">
          <Form onSubmit={handleSubmit} className="stories-form-bg">
            <h2 className="pt-3 pb-3 ps-3">Connect with us.</h2>
            <FormGroup className="ms-3 me-3">
              <Label for="name">Full Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="ms-3 me-3">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="ms-3 me-3">
              <Label for="email">Phone</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your phone"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="ms-3 me-3">
              <Label for="email">Company</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your Company"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="ms-3 me-3">
              <Label for="email">Messege</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your Messege"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <div className=" d-flex justify-content-center pb-3">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      )}
    </section>
  );
}

export default Stories;
