import icon_green_overlay from "../../../assets/dedicated_icons/icon_green_overlay.svg";
import magento_development from "../../../assets/dedicated_icons/magento_development.svg";
import icon_overlay from "../../../assets/dedicated_icons/icon_overlay.svg";
import iphone_app_development from "../../../assets/dedicated_icons/iphone_app_development.svg";
import angular_development from "../../../assets/dedicated_icons/angular_development.svg";
import aws_development from "../../../assets/dedicated_icons/aws_development.svg";
import laravel_development from "../../../assets/dedicated_icons/laravel_development.svg";
import android_development from "../../../assets/dedicated_icons/android_development.svg";
import asp_net_development from "../../../assets/dedicated_icons/asp_net_development.svg";
import wordpress_development from "../../../assets/dedicated_icons/wordpress_development.svg";
import frontend_development from "../../../assets/dedicated_icons/frontend_development.svg";
import hire_qa from "../../../assets/dedicated_icons/hire_qa.svg";
import php_development from "../../../assets/dedicated_icons/php_development.svg";
import mobile_app_development from "../../../assets/dedicated_icons/mobile_app_development.svg";
import flutter_app_development from "../../../assets/dedicated_icons/flutter_app_development.svg";
import full_stack_development from "../../../assets/dedicated_icons/full_stack_development.svg";
import react_js_development from "../../../assets/dedicated_icons/react_js_development.svg";
import cross_platform_app_development from "../../../assets/dedicated_icons/cross_platform_app_development.svg";
import web_development from "../../../assets/dedicated_icons/web_development.svg";
import mean_stack_development from "../../../assets/dedicated_icons/mean_stack_development.svg";
import on_demand_solutions from "../../../assets/dedicated_icons/on_demand_solutions.svg";

function Img_icons() {
  const icon_dict = [
    {
      image: magento_development,
      text: "Magento",
    },
    {
      image: iphone_app_development,
      text: "iPhone App Developer",
    },
    {
      image: angular_development,
      text: "Angular Developer",
    },
    {
      image: aws_development,
      text: "AWS Developer",
    },
    {
      image: laravel_development,
      text: "Laravel Developer",
    },
    {
      image: android_development,
      text: "Android Developer",
    },
    {
      image: asp_net_development,
      text: "ASP.NET Developers",
    },
    {
      image: wordpress_development,
      text: "WordPress Developers",
    },
    {
      image: frontend_development,
      text: "Frontend Developers",
    },
    {
      image: hire_qa,
      text: "Hire QA",
    },
    {
      image: php_development,
      text: "PHP Developers",
    },
    {
      image: mobile_app_development,
      text: "Mobile App Developers",
    },
    {
      image: flutter_app_development,
      text: "Flutter App Developers",
    },
    {
      image: full_stack_development,
      text: "Full-Stack Developers",
    },
    {
      image: react_js_development,
      text: "ReactJS Developers",
    },
    {
      image: cross_platform_app_development,
      text: "Cross-platform App Developers",
    },
    {
      image: web_development,
      text: "Web Developer",
    },
    {
      image: mean_stack_development,
      text: "MEAN Stack Developer",
    },
  ];
  return { icon_dict, icon_overlay, icon_green_overlay };
}

export default Img_icons;
