import React from "react";

export const DesignCards = () => {
  const card_list = [
    {
      title: "Providing Limited Choices",
      content: `Hick-Hyman's law states that whenever you give too
      many options, users can deviate from taking the
      actual intended decision. Hence to minimize this
      risk, we tend to reduce the number of options to
      make users feel at ease. For every project in New
      York for web design we go, we keep this in mind.`,
      a_tag: `Apply Hick's Law`,
    },
    {
      title: "Blank Spaces? Not As Bad As You Think",
      content: `No matter how stupid it sounds, we always leave white
      spaces between contents. Without it, the contents feel
      too crammed and suffocating to read. Contrary to
      popular belief, leaving white spaces helps the viewer
      to focus on the content. So, as a web design company
      in NYC, we maintain it.`,
      a_tag: `Use Negative space`,
    },
  ];
  return card_list;
};

export const LowerDesignCards = () => {
  const card_list = [
    {
      title: "CTA Insertions In Core Visual Areas",
      content: `Whether a design is pro-mobile or web responsive, it is
      of no use if it fails at generating optimum conversions.
      Having said that, we strategically place powerful CTAs
      in every relevant information. Thus, we help you to
      generate an optimum level of CTA.`,
      a_tag: `Rule of thirds`,
    },
    {
      title: "Best Blend Of Color And Contrast",
      content: `Neuromarketing studies reveal that the right color
      combination enhances the visual appeal of a product. With
      considerable knowledge on color psychology, our design
      experts engage in color-contrast optimization, providing
      the right color combination that fetches optimum CTA.`,
      a_tag: `Build hierarchy`,
    },
    {
      title: "Specific Action And Familiarity Reinforcement",
      content: `It is much like celebrity endorsement, just on a web
      platform. Familiar faces build trust in users and increase
      CTAs. People often empathize with a familiar face. So, we
      must incorporate as many of them in the web design.`,
      a_tag: `Establish Recognition`,
    },
    {
      title: "Consistent Branding",
      content: `To effectively market a website, we, as an experienced web
      design agency in New York, always suggest maintaining a
      balanced consistency in brand color, essence, and faces
      throughout. It enhances user experience in both computer
      and mobile-friendly web designs.`,
      a_tag: `Set the tone`,
    },
  ];
  return card_list;
};
