import orange_agile from "../../../assets/dedicated_icons/orange_agile.svg";
import orange_team from "../../../assets/dedicated_icons/orange_team.svg";
import orange_cost from "../../../assets/dedicated_icons/orange_cost.svg";
import orange_attention from "../../../assets/dedicated_icons/orange_attention.svg";
import orange_project from "../../../assets/dedicated_icons/orange_project.svg";
import orange_collaborative from "../../../assets/dedicated_icons/orange_collaborative.svg";

function Team_list() {
  const team_dict = [
    {
      img: orange_team,
      title: "High-Quality Talent Team",
      content:
        "We will introduce a skilled team of developers for you to choose the most optimistic and passionate offshore developer. With this you can save enough time building an in-house team of developers.",
    },
    {
      img: orange_cost,
      title: "Cost-Effective",
      content:
        "The quick and easy onboarding of your virtual team turns out to be cost-effective as you can save on infrastructure, hiring and staffing. Scale your team in a few days at a relatively cheaper cost.",
    },
    {
      img: orange_attention,
      title: "More Attention On Business",
      content:
        "When you hire a dedicated development team, you get a team of project managers, designers and developers. The offshore team will ensure delivery and quality of software, while you can pay more attention to your business.",
    },
    {
      img: orange_project,
      title: "Absolute Project Control",
      content:
        "With a team of remote developers, you can plan and develop the project as per your business needs. The hired development team can work ceaselessly with you for delivery and post-deployment support.",
    },
    {
      img: orange_collaborative,
      title: "Collaborative",
      content:
        " With frequent and open communication through Skype, Email, Video and Audio conferencing you can uncover opportunities, set tight feedback loops and ensure you have complete check over project deadlines.",
    },
    {
      img: orange_agile,
      title: "Agile & Adaptive Development",
      content:
        " We are here for your clear vision and idea. Get cross-functional agile development teams to keep your project running faster while diminishing unwanted practices.",
    },
  ];
  return team_dict;
}

export default Team_list;
