import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaYoutube, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                        <li><a href="https://www.facebook.com/3waytech" target="_blank"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/3waytech1" target="_blank"><FaTwitter /></a></li>
                        <li><a href="https://www.instagram.com/3waytech" target="_blank"><FaInstagram /></a></li>
                        <li><a href="https://www.behance.net/gallery/159120637/3-Way-Brand-Identity" target="_blank"><FaBehance /></a></li>
                        <li><a href="https://www.linkedin.com/company/3-way-technologies" target="_blank"><FaLinkedinIn /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCDnbDSLFKDLlWmvzjaaWB_g" target="_blank"><FaYoutube /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>Looking for the best quote? Look no further! With our instant quote service, you can get a competitive and accurate quote in few minutes.</p>
                                    {/* <form> */}
                                        {/* <div className="input-group"> */}
                                            {/* <span className="mail-icon"><FaWhatsapp /> </span> */}
                                            {/* <input type="text" className="form-control" placeholder="Your Message" /> */}
                                            <a className="axil-btn btn-large btn-fill-white" href="https://wa.me/+919998692099/?text=Hi%2C%20I%20have%20connect%20to%20you%20via%20website."><FaWhatsapp /> Chat Now</a>
                                          
                                            {/* <button className="subscribe-btn" >Connect Now</button> */}
                                        {/* </div> */}
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/service/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li>
                                                {/* <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li> */}
                                                <li><Link to={process.env.PUBLIC_URL + "/projects"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                {/* <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li> */}
                                                {/* <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://3waytech.co/">3WAYSOURCE LLP</a>.</span>
                               
                            </div>
                          
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                {/* <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul> */}
                                  <img style={{width:'200px'}} src={"https://digitalindia.gov.in/wp-content/uploads/2022/03/logo.svg"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;