import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";

function Mobile_design() {
  return (
    <section className="mobile-bg">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-5 pt-5">
            <h3 className="text-start mobile-text">
              Emphasis On Mobile First Web Design
            </h3>
            <p className="text-start">
              In this age of information, mobile phones are no longer a trend,
              but a necessity. Our designers craft user experiences and make
              them compatible with every platform. We build prototypes ranging
              from small to large-sized screens. As we also stand out as a
              responsive web design company we invest in the crucial aspects of
              web design content, given the limited real estate on smaller
              screens.
            </p>
            <p className="text-start">
              Thus, it has become increasingly important to develop pro-mobile
              web designs, as Google is now inclined towards using them for
              indexing and ranking.
            </p>
            <h5 className="text-start pb-3 pt-3">Convinced?</h5>
            <div className="pb-5">
              <a className="btn btn-dark" href="/contact">Discuss your project</a>
            </div>
          </div>
          <div className="col-lg-5 pt-5">
            <div className="mobile-design-process-3"></div>
            <div className="d-flex align-items-end">
              <div className="mobile-design-process-1"></div>
              <div className="mobile-design-process-2"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mobile_design;
