import React from 'react';
import LocationData from '../../data/contact/LocationData.json';

const allData = LocationData;

const ContactLocation = () => {
    return (
        <>
            {allData.map((data, index) => (
                <div className="col-lg-3 col-sm-6" key={index}>
                    <div className="office-location" style={{width:'100%'}}>
                        <div className="thumbnail">
                            <img src={process.env.PUBLIC_URL + data.thumb} alt="Office" />
                        </div>
                        <div className="content">
                            <h4 className="title">{data.title}</h4>
                            <p>{data.address}</p>
                        </div>
                    </div>
                </div>
                
            ))}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14686.918702083154!2d72.5244573!3d23.0336957!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e854b1b1f4197%3A0x3d03a48fd4252611!2s3%20Way%20Technologies!5e0!3m2!1sen!2sin!4v1678985201178!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </>
    )
}

export default ContactLocation;