import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import { useState, useEffect } from "react";
import doted from "../../../assets/dedicated_icons/doted.svg";

function Do() {
  const [mobileView, setMobileView] = useState(
    window.innerWidth <= 550 ? true : false
  );
  const [screenChange, setScreenChange] = useState(false);
  const [webView, setWebView] = useState(
    window.innerWidth > 550 ? true : false
  );

  useEffect(() => {
    const setData = () => {
      setScreenChange(!screenChange);
      if (window.innerWidth < 550) {
        setMobileView(true);
        setWebView(false);
      } else {
        setWebView(true);
        setMobileView(false);
      }
    };

    window.addEventListener("resize", setData);

    return () => {
      window.removeEventListener("resize", setData);
    };
  }, [webView, mobileView, screenChange]);
  return (
    <section>
      <div className="container">
        <div>
          <div className="row pb-5">
            <h3>Who We Are & What We Do</h3>
          </div>
          <div className="do-right-slant">
            <div className="do-right-slant-dot">
              <img src={doted}></img>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="do-half-circle-1 col-lg-4"></div>
                <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                  <h4>We are Collaborative</h4>
                </div>
                <p className="pt-3">
                  We work as your extended team and bring out the best in our
                  development, design and marketing services. We understand your
                  requirement, discuss various queries and mutually make the
                  best out of it.
                </p>
              </div>
              <div className="row pt-5">
                <div className="do-half-circle-2 col-lg-4"></div>
                <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                  <h4>We are Indispensable</h4>
                </div>
                <p className="pt-3">
                  We believe the actual problem resides behind the problem
                  itself. The forte in technology helps to solve the challenges
                  in the project and make us win.
                </p>
              </div>
              <div className="row pt-5">
                <div className="do-half-circle-3 col-lg-4"></div>
                <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                  <h4>We Unleash the Genius.</h4>
                </div>
                <p className="pt-3">
                  We unleash the unconventional aspects of your project with our
                  expertise. We love those hidden aspects and let it shine.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <div className="do-right-img do-img-size-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="do-left-slant">
            <div className="do-left-slant-dot">
              <img src={doted}></img>
            </div>
          </div>
          <div className="row pt-5">
            {mobileView && (
              <div className="col-lg-6">
                <div className="row">
                  <div className="do-half-circle-1 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We Don't Stop Designing.</h4>
                  </div>
                  <p className="pt-3">
                    Design and development certainly influence each other. Our
                    expertise blurs the lines between them so the project can
                    thrive in each of the phases together.
                  </p>
                </div>
                <div className="row pt-5">
                  <div className="do-half-circle-2 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We Diminish Risk Early.</h4>
                  </div>
                  <p className="pt-3">
                    Our prior attention is given to the most challenging aspects
                    of the project - from research to implementation, while
                    providing you the belief that we will gain objectives in
                    productive time.
                  </p>
                </div>
                <div className="row pt-5">
                  <div className="do-half-circle-3 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We iterate quickly</h4>
                  </div>
                  <p className="pt-3">
                    We deliver you the working versions of your concepts and
                    designing at pace, so you can share the feedback early and
                    enable us to work on project improvements as per the need.
                  </p>
                </div>
              </div>
            )}
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <div className="do-left-img do-img-size-left"></div>
              </div>
            </div>
            {webView && (
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="do-half-circle-1 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We Don't Stop Designing.</h4>
                  </div>
                  <p className="pt-3">
                    Design and development certainly influence each other. Our
                    expertise blurs the lines between them so the project can
                    thrive in each of the phases together.
                  </p>
                </div>
                <div className="row pt-5">
                  <div className="do-half-circle-2 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We Diminish Risk Early.</h4>
                  </div>
                  <p className="pt-3">
                    Our prior attention is given to the most challenging aspects
                    of the project - from research to implementation, while
                    providing you the belief that we will gain objectives in
                    productive time.
                  </p>
                </div>
                <div className="row pt-5">
                  <div className="do-half-circle-3 col-lg-4"></div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                    <h4>We iterate quickly</h4>
                  </div>
                  <p className="pt-3">
                    We deliver you the working versions of your concepts and
                    designing at pace, so you can share the feedback early and
                    enable us to work on project improvements as per the need.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Do;
