import React from "react";
import SEO from "../common/SEO";
import "../assets/css/style.css";
// import "../assets/css/ecom.css";
import Contact from "./Contact";


const EcomDevelopment = () => {
  return (
    <>
      <SEO title="E-Commmerce Development" />
    

<section className="grey-panel">
    <div className="container mt-5">
      <h2>
        Want to start a store?
      </h2>
      <p>
      Ecommerce web development is the process of building an ecommerce website. Frontend and backend development for ecommerce sites include checkout systems, payment processing, site security, and more. Keep reading to more about ecommerce web development's meaning!
      </p>
      <p>
      We build responsive and user-friendly online stores for digital brands to connect with customers and gain a competitive edge. Our storefronts have appealing web designs for great user experiences, intuitive shopping carts for fast checkout, and SEO and marketing automation tools for increased store visibility.
      </p>
      <p>
      Through mobile app development, we provide retailers with an additional digital touchpoint to reach their target audience and deliver convenient shopping experiences. Our mobile shopping apps enable you to track your customers’ location for targeted marketing, provide fast credit card or digital wallet checkout, and use push notifications and voice shopping functionality to improve customer engagement.
      </p>
    </div>
  </section>
  <img
  src="/images/ecom.png"
  width={'100%'}
/>


<section className="grey-panel">
    <div className="container mt-5">
      <h2>
      FULL-FEATURED SOLUTIONS FOR DIVERSIFIED ECOMMERCE
      </h2>
      <p>
      We develop B2B commerce hubs to facilitate interaction between your business and your partners, vendors, and clients and accelerate high-volume sales. With our B2B solutions, you can streamline order and inventory management, differentiate customers through contract pricing and specialized catalogs, and deliver orders in split shipments.
      </p>
      <p>
      Our ecommerce development company deploys multi-lingual and multi-currency marketplaces to facilitate sales and interactions between multiple manufacturers or distributors and consumers. Through special features for marketplace owners, vendors, and customers, all users get effective tools to make their selling and buying experiences easy and enjoyable.
      </p>
    </div>
  </section>
  <img
  src="/images/ecom2.png"
  width={'100%'}
/>
  <Contact/>

    </>
  );
};

export default EcomDevelopment;
