import React from "react";

function CapabilityCards() {
  const cards_list = [
    {
      text: "Web Design",
      content: `How about engaging your audience in 5 seconds with a stellar
      web design experience? Our strategic planning and design
      methodologies can make this happen.`,
    },
    {
      text: "Web Development",
      content: `From ideation, concept development and prototyping to
      finally launching your website, we do it all with our 12+
      years of experience as a leading web development company in
      New York.`,
    },
    {
      text: "Brand Strategy",
      content: `We go through an in depth research to offer you a brand
      strategy that's better than your competitor's to position
      you absolutely high and memorable in the market.`,
    },
  ];
  return cards_list;
}

export default CapabilityCards;
