import eshipper_portfolio_2x from "../../../assets/web_design_icons/eshipper-portfolio_2x.jpeg";
import portfolio_title_3 from "../../../assets/web_design_icons/portfolio-title-3.svg";
import colorcon_portfolio_2x from "../../../assets/web_design_icons/colorcon_portfolio_2x.jpeg";
import portfolio_title_4 from "../../../assets/web_design_icons/portfolio_title_4.svg";
import Sofy_portfolio_2x from "../../../assets/web_design_icons/Sofy_portfolio_2x.jpeg";
import portfolio_title_5 from "../../../assets/web_design_icons/portfolio_title_5.svg";
import freshclinics_portfolio_2x from "../../../assets/web_design_icons/freshclinics_portfolio_2x.jpeg";
import portfolio_title_6 from "../../../assets/web_design_icons/portfolio_title_6.svg";

const Data = [
  {
    img1: eshipper_portfolio_2x,
    img2: portfolio_title_3,
    text1: `Website redesign for one of Canada's leading shipping
    companies during the COVID-19 pandemic.`,
    text2: `We were hired to help eShipper create a brand-new
    website which shall enable them to convert more of the
    huge amount of traffic they already received and
    improve their brand perception. Through a data and
    research driven process, we created a modern website
    which resulted in 3x leads and 4x subscriptions just 2
    months post its launch.`,
  },
  {
    img1: colorcon_portfolio_2x,
    img2: portfolio_title_4,
    text1: `Re-imagined and custom engineered the B2B ordering portal
    for this $500m global pharma giant.`,
    text2: `As consulting-led partners, we audited, re-designed and
    engineered a highly secure and gated ordering portal for
    Coloron's customers to place, manage & track orders, while
    also managing their accounts, ledgers, order history,
    documents, compliances and other artifacts related to
    pharmaceutical components.`,
  },
  {
    img1: Sofy_portfolio_2x,
    img2: portfolio_title_5,
    text1: `Custom engineered this robust on-demand streaming platform
    with AI and ML capabilities.`,
    text2: `Unified Infotech designed and developed this highly
    sophisticated on-demand streaming platform for short
    movies, along with advanced AI capabilities and usage of
    big data.`,
  },
  {
    img1: freshclinics_portfolio_2x,
    img2: portfolio_title_6,
    text1: `Engineered this SaaS based medical compliance solution
    used by over 1200+ clinics.`,
    text2: `Unified Infotech was the end-to-end technology partner in
    transforming an idea into a highly successful product with
    many first of its kind features in the medical compliance
    category.`,
  },
];

export default Data;
