import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState, useEffect } from "react";
import Data from "./item";

function Platform() {
  const [mobileView, setMobileView] = useState(
    window.innerWidth <= 500 ? true : false
  );
  const [screenChange, setScreenChange] = useState(false);
  const [webView, setWebView] = useState(
    window.innerWidth > 500 ? true : false
  );
  console.log("window.innerWidth ----- ", window.innerWidth);

  useEffect(() => {
    const setData = () => {
      setScreenChange(!screenChange);
      if (window.innerWidth < 500) {
        setMobileView(true);
        setWebView(false);
      } else {
        setWebView(true);
        setMobileView(false);
      }
    };

    window.addEventListener("resize", setData);

    return () => {
      window.removeEventListener("resize", setData);
    };
  }, [webView, mobileView, screenChange]);

  return (
    <section>
      <div className="container slider-container-height">
        <div className="row">
          <OwlCarousel
            className="owl-theme-st"
            loop
            margin={100}
            items={1}
            autoplayTimeout={1000}
            autoWidth={false}
          >
            <div class=" pt-3">
              <div className="row align-items-center ">
                {webView && (
                  <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12">
                    <img
                      src="https://www.unifiedinfotech.net/assets/images/new-home/openreel-portfolio@2x.jpg"
                      className="Platform-slider-img mb-5"
                      alt="plat1"
                    ></img>
                  </div>
                )}
                <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                  <div>
                    <div className="mb-5 ms-3">
                      <img
                        src="https://www.unifiedinfotech.net/assets/images/portfolio-title-1.svg"
                        className="Platform-text-slider-img text-start"
                        alt="plat2"
                      ></img>
                    </div>
                    <h3 className="mb-5 ms-3">
                      Highly successful SaaS video platform with a patented
                      technology used by global enterprises.
                    </h3>
                    {mobileView && (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img
                          src="https://www.unifiedinfotech.net/assets/images/new-home/openreel-portfolio@2x.jpg"
                          className="Platform-slider-img mb-5"
                          alt="plat3"
                        ></img>
                      </div>
                    )}
                    <p className="mb-5 ms-3">
                      From an idea on a document, to now this
                      multi-million-dollar SaaS business, we are a proud
                      end-to-end technology partner and continue to scale the
                      product.
                    </p>
                    <a className="btn btn-dark mb-5 ms-3" href="/contact">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {Data.map((data) => {
              return (
                <div className=" pt-3">
                  <div className="row align-items-center ">
                    <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12">
                      <img
                        src={data.img1}
                        className="Platform-slider-img mb-5"
                        alt="plat6"
                      ></img>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                      <div>
                        <div className="mb-5 ms-3">
                          <img
                            src={data.img2}
                            className="Platform-text-slider-img"
                            alt="plat7"
                          ></img>
                        </div>
                        <h3 className="mb-5 ms-3">{data.text1}</h3>
                        <p className="mb-5 ms-3">{data.text2}</p>
                        <a className="btn btn-dark mb-5 ms-3" href="/contact">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}
export default Platform;
